<template>
  <div>
    <CRow>
      <CCol sm="12">
        <h2>New Case File</h2>
      </CCol>
    </CRow>
    <CForm>
      <CRow>
        <CCol md="12">
          <CCard>
            <CCardBody>
              <CInput
                label="Date Opened"
                type="date"
                required="required"
                horizontal
                v-model="form.date"
              />
              <p class="text-red-500">
                <small>
                  <!-- {{ errors.date[0] }} -->
                </small>
              </p>

              <CInput
                description=""
                label="Client Name"
                required="required"
                horizontal
                autocomplete="name"
                v-model="form.client_name"
              />
              <!-- <p class="text-red-500" v-if="errors.client_name">
                <small>
                  {{ errors.client_name[0] }}
                </small>
              </p> -->

              <CInput
                description=""
                label="File Reference"
                required="required"
                horizontal
                autocomplete="ref"
                v-model="form.file_reference"
              />
              <!-- <p class="text-red-500" v-if="errors.file_reference">
                <small>
                  {{ errors.file_reference[0] }}
                </small>
              </p> -->

              <CRow class="mb-3">
                <label class="col-sm-4">Category</label>
                <select
                  class="col-sm-7 form-control"
                  required="required"
                  placeholder="Please select"
                  v-model="form.category"
                >
                  <option v-for="c in categories" :key="c">
                    {{ c }}
                  </option>
                </select>
              </CRow>
              <!-- <p class="text-red-500" v-if="errors.category">
                <small>
                  {{ errors.category[0] }}
                </small>
              </p> -->

              <CRow class="mb-3">
                <label class="col-sm-4">Primary User</label>
                <select
                  class="col-sm-7 form-control"
                  required="required"
                  placeholder="Please select"
                  v-model="form.pri_user"
                >
                  <option v-for="l in lawyers" :key="l">
                    {{ l }}
                  </option>
                </select>
              </CRow>
              <!-- <p class="text-red-500" v-if="errors.pri_user">
                <small>
                  {{ errors.pri_user[0] }}
                </small>
              </p> -->

              <CInput
                label="Primary User Bring-up Date"
                type="date"
                horizontal
                v-model="form.pri_user_bu_date"
              />
              <CRow class="mb-3">
                <label class="col-sm-4">Secondary User</label>
                <select
                  class="col-sm-7 form-control"
                  placeholder="Please select"
                  v-model="form.sec_user"
                >
                  <option v-for="l in lawyers" :key="l">
                    {{ l }}
                  </option>
                </select>
              </CRow>

              <CInput
                label="Secondary User Bring-up Date"
                type="date"
                horizontal
                v-model="form.sec_user_bu_date"
              />

              <CTextarea
                label="Matter Summary"
                required="required"
                placeholder="Summary"
                horizontal
                rows="6"
                v-model="form.summary"
              />

              <!-- <p class="text-red-500" v-if="errors.summary">
                <small class="text-red-500">
                  {{ errors.summary[0] }}
                </small>
              </p> -->
            </CCardBody>

            <CButton
              @click="submit_save"
              style="padding-top: 10px;"
              color="info"
              square
            >
              <h3>Save</h3>
            </CButton>
          </CCard>
        </CCol>
      </CRow></CForm
    >
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "FilesActive",
  components: {},
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      token: localStorage.getItem("token"),
      form: {
        client_name: "",

        file_reference: "",
        date: "",
        category: "",
        pri_user: "",
        pri_user_bu_date: "",
        sec_user: "",
        sec_user_bu_date: "",
        summary: "",
      },
      categories: [],
      lawyers: [],
      errors: {},
    };
  },
  mounted() {
    this.fetch_lawyers();
    this.fetch_categories();
  },
  methods: {
    fetch_categories() {
      const axios = require("axios");

      var vm = this;

      axios
        .get("/datasets/categories")
        .then(function(res) {
          // handle success
          vm.categories = res.data;
          console.log(vm.categories);
          // vm.loading = false;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
          // vm.loading = false;
        });
    },
    fetch_lawyers() {
      const axios = require("axios");

      var vm = this;

      axios
        .get("/datasets/lawyers")
        .then(function(res) {
          // handle success
          vm.lawyers = res.data;
          console.log(vm.lawyers);
          // vm.loading = false;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
          // vm.loading = false;
        });
    },
    submit_save() {
      const axios = require("axios");
      console.log(this.form);
      var vm = this;
      axios
        .post("/cases/new", {
          ...vm.form,
          user_initials: vm.user.initials,
        })
        .then((res) => {
          console.log(res);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Saved!",
            showConfirmButton: false,
            timer: 1500,
          });
          vm.$router.push(`/case/details/${res.data.data.id}`);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response.data);
          // console.log(err.response.status);
          // console.log(err.response.headers);
          vm.errors = err.response.data.errors;

          // console.log(vm.errors.message);
          Swal.fire("Oops...", err.response.data.message, "error");
        });
    },
  },
};
</script>

<style scoped>
.text-red-500 {
  color: red !important;
  font-weight: 500 !important;
}
</style>
